.btn-check:checked+.btn, .btn.active, .btn.show, .btn:first-child:active, :not(.btn-check)+.btn:active{
    background-color: #eb3d34 !important;
    border: none !important;
    color: #fff !important;
}

.btn-outline-secondary {
    /* color: #eb3d34; */
    /* border: 2px dotted; */
    border: 1px solid #ddd;
    /* box-shadow: 0px 0px 7px #eb3d3473; */
}
.btn-outline-secondary:hover {
   
    /* border: 2px dotted; */
    box-shadow: 0px 0px 7px #eb3d3473;
}