.DetailsSession{
    min-height: 85vh;
}
.DetailsSession aside .item{
    background: #efefefdd;
    
}
.DetailsSession aside   .div_parent
{
    max-height: 66vh;
    overflow: auto;
    min-height: 38vh;
    
}
.DetailsSession .nav_section_details a
{
    margin: 4px 4px;
    box-shadow: 0px 0px 8px 0px #e7e3e3;
    border-radius: 3px;
    padding: 2px 5px;
}