
/* .payment{
    background: #2a2f38;
    font-size: 40px;
    color: #fff;
} */
button{
    border-radius: 12px !important;
}
.total{
    margin: 20px auto ;
    background: #fff;
    box-shadow: 0px 0px 10px 0px #e7e6e6;
    border-radius: 50px;
    padding: 20px;
}
.total .number-total
{
    color: #eb3d34;
    font-size: 137px;
    margin-bottom: 0px;
}
.total .message 
{
    font-size:27px;
    color:#2a2f38
}
.button-div
{
    font-size: 42px;
    color: #2a2f38;
    background: #f4f4f4;
}
button.btn.m-2.w-100.p-3.btn-outline-secondary {
    font-size: 25px;
    color: #2a2f38;
}
.input-email
{
    background: #edecec;
    border: none;
    font-size: 35px;
    display: flex;
    align-items: center;
   
    padding: 2px;
    border-radius: 20px;
    direction: initial;
}
.input-email input{
    background-color: #edecec;
    border: none;
}
.input-email span
{
    background: #2a2f38;
    color: #fff;
    padding: 0px 12px;
    margin: 5px;
    border-radius: 11px;
}
.details-company hr
{
    margin:3px auto;
    border-width: 2px;
    
}
.invoce {
    margin: 50px 20px;
    box-shadow: 0px 0px 20px 6px #ddd;
    padding: 20px;
    border-radius: 15px;
}

.invoce .div-price{
    font-size: 25px;
}
.total-prices
{
    display: flex;
    align-items: stretch;
    justify-content: space-evenly;
    font-size: 30px;
    font-weight: bold;
}
 iframe{
    width: 100%;
    height: 77vh;
 }


 