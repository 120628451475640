.main_Tabel{
    position: relative;
}
.main_Tabel .Square {
    width: 100px;
    height: 100px;
  

  }
  .main_Tabel .circle {
    width: 100px;
    height: 100px;
    border-radius: 50% ;
 

  }
  .main_Tabel .allDrownDiv{
    /* background: rgb(0 0 0 / 8%); */
     margin: 3px;
    box-shadow: 3px 4px 6px 2px #bfbfbfd4;
    position: absolute;
    background: linear-gradient(90deg, #B58357 20%, #EBD5B7 20%, #EBD5B7 25%, #B58357 25%, #B58357 45%, #EBD5B7 45%, #EBD5B7 50%, #B58357 50%, #B58357 70%, #EBD5B7 70%,#EBD5B7 74%,#B58357 48%);
   
    transform:
    perspective(900px)
    rotateX(60deg)
    scale(1);
    box-shadow: 15px 20px 20px 0px #584f4fa3;
    transition: 0.5s ease all;
   
  
  
  
  
  }
  .main_Tabel .triangle{
    border-radius: 41px  ;
    width: 150px ;
    height: 100px;
  
    

  }
  .main_Tabel .rectangle{
    width: 200px;
    height: 100px;
 
  }
  .main_Tabel .tabel_number
  {
    background: #eb3d34;
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    font-weight: bold;
    font-size: 17px;
 
    color: #ffffff;
    transform: perspective(2000px) translate3d(0px, -101px, 372px) rotateX(18deg) scale3d(0.86, 1.75, 6) translateY(50px);
  }
 
  nav a.active{
    background: #eb3d34 !important;
    color: #fff !important;
  }
  .number_clint{
    
    

    top: 110%;
    position: absolute;
    transform: scaleY(1.5);

  }