.prodects{
    min-height: 84vh;
}
.select_div_cstigory .active{
    background: #eb3d34 !important;
    color: #fff;
    transition: .5s;
    border: none;
}
.select_div_cstigory {
    background: #fff;
  
  }
  .select_div_cstigory select option
  {
    border: 1px solid #45454529;
    padding: 5px 6px;
    box-shadow: 2px 6px 6px 0px #eae3e3;
    margin: 11px auto;
    overflow: hidden;
    width: 93%;
    font-size: 18px;
    border-radius: 21px;
    text-align:center
  }
  .select_div_cstigory ul li:hover {
 
    background: #eb3d34 !important;
    color: #fff;
    transition: .5s;
    border: none;
   cursor: pointer;
     
  }
  .card .img_card {
    height: 110px;
    object-fit: contain;
}
.all_prodects {
    background-color: #f1f1f1;
 
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    border-radius: 17px;
    overflow: scroll;
    height: 71vh;
}
li.list-group-item.active{
  z-index: 0;
}