.HomePage{
    min-height: 85vh;

}
.HomePage .item-session{
    background-color: #fff;
}
@media screen and (min-width:990px) {
    .item-session{
        width: 80%;
    }
}
.HomePage .card
{
    max-width: 540px
}
.dropdown-menu li a:active{
    color: unset;
    background: unset !important;
}

.title_cart
{
    /* background: #e4e2e2; */
    color: #fff;
    border-radius: 14px;
}
.div_lift_Filter div{
border-radius: 10px  ;
}
@media screen and (max-width:958px) {
    .div_lift_Filter {
    position: unset !important;
    display: flex;
    align-items: center;
    justify-content: space-around;
}  
 
}
@media screen and (max-width:350px) {
    .div_lift_Filter {
        flex-direction: column
     
}  
 
}
 