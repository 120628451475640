.add_Prodect{
    min-height: 84vh;
}
.Product_form img{
    height: 150px;
}
.Product_form {
    padding: 3px 20px !important;
    max-width: 300px;
    margin: auto;
    border-radius: 12px;

}
.custom-file-upload {
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    color: #333;
    background-color: #fff;
  }
  
  .custom-file-upload input[type="file"] {
    display: none;
  }
  .alert_div{
    z-index: 100000000000;
  }