.SessionPayments .accordion-button:not(.collapsed) {
    color: inherit;
    background-color: inherit;
    box-shadow: unset;
    border-radius: unset;
}
.SessionPayments table tr th,.SessionPayments table tr td{
    width: 25%;
    min-width: 100px;
}
/* .SessionPayments .accordion-button{
width: auto;
} */
.SessionPayments
{
    min-height: 83vh;
}
@media print{
    .none_print{
        display: none;
    }
}