
.ClosingSession .modal-body{
    font-size: 18px !important;
    overflow: auto !important;
    max-height: 384px !important;
}
.ClosingSession  div#staticBackdrop{
position: absolute !important;
}
@media screen and (max-width:720px) {

    .ClosingSession .modal-body{
        font-size: 16px ;
    } 
    .ClosingSession button{
        font-size: 16px;
    } 
  
}
  .ClosingSession.modal-dialog.modal-dialog-scrollable  {
    position: fixed;
    right: 20px;
    left: 20px;
}
 
@media print {
    
    .header_print_block,.footer_print_block{
        display:block;
       };
  
}