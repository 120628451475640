
.payment{
    background: #f4f4f4;
    font-size: 32px;
    color: #2a2f38 !important;
    border-radius: 14px !important;

}
.payment-page
{
    min-height: 85vh;

}
button{
    border-radius: 12px !important;
}
.btn-next-prevn,.btn-next-prev:hover{
    background: #f4f4f4;
    border: none;
    box-shadow: 1px 1px 4px #00000057;
    color: #000;
    border: 1px solid #f4f4f4;
    font-size: 20px;
}
.payment .total{
    margin: 20px auto !important;
    background: #fff;
    box-shadow: 0px 0px 5px 0px #e9e9e931;
    border-radius: 10px;
    padding: 18px;
}
.total .number-total
{
    color: #eb3d34;
    font-size: 30px !important;
    margin-bottom: 0px;
}
.total .message 
{
    font-size:15px !important;
    margin-top: 10px;
    color:#2a2f38
}
.button-div
{
    font-size: 30px !important;
    color: #2a2f38;
    background: #f4f4f4;
    margin-bottom: 5px;
}

.div-value-pay{
    font-size: 30px;
    color: #2a2f38;
    background-color: #f4f4f4;
}

button.btn.m-2.w-100.p-3.btn-outline-secondary {
    font-size: 20px !important;
    color: #2a2f38;
    margin-top: 20px !important;
}
button.btn.m-2.p-3.btn-outline-secondary:hover{
    color:#fff;
    background: #eb3d34;;
}
.add-numbers
{
    bottom: 20%;
    width: 24%;
    right: 8px;
    background: #f3f3f4;
}
.payment-page  .functions {
    bottom: 25%;
    width: 23%;
    right: 15px;
    background: #f3f3f4;
}
  .functions .calculator-key {
        font-size: 30px;
        width: 100% !important;
        line-height: 1;
    }
    .waypay button{
        line-height: 1;
        height: 90px;
        font-size: 30px !important;

    }
    .color-number-price{
        color: rgb(25, 211, 0);
    }