footer{
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 17px;
    color: #2a2f38;
}
@media screen and (max-width:640px) {
    footer{
        display: flex;
        flex-wrap: wrap;
    justify-content: center;
      
    }
}
footer .dropdown-item{
    cursor: pointer;
}