.react-tel-input .form-control{
    width: 100% !important;
}
.addCustomer  {
   min-height: 68vh;
}
.react-tel-input {
  
    direction: initial;
}
.alert_div
{    position: fixed;
    top: 20%;
    right: 20px;
    left: 20px;
    text-align: center;
opacity: .8;
}
.text_red{
    color: #eb3d34;
}
.input_red{
    border:1px solid #eb3d34 ;
}