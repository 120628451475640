
h3, .div-price
{
    font-size: 33px;
    color: #2a2f38 !important;
}

.item{
    margin-top: 5px;
    line-height: .3;
}
.all-aside{
    padding: 5px 5px 2px;
    border-radius: 26px;
    background-color: #e3e3e3;
}
.total-price.d-flex.justify-content-between {
    background: #fff;
    padding: 20px;
    border-radius: 40px 40px 0px 0px;
}
.total-price
{
    box-shadow: 0px 0px 6px 0px #3a3a3a4a;
    background: #ffffff;
    border-radius: 40px;
    font-size: 20px;
    color: #2a2f38;
    font-weight: bold;
    justify-content: space-between !important;
}
.total-price p{
    margin: 0px;
}
.num-total {
    background: #e3e3e3;
    border-radius: 40px;
    padding: 6px;
}
.items-price
{
    border-radius: 17px;
    background: #fff;
    padding: 0px;
    height: 28vh;
    margin-bottom: 1px;
    position: relative;
    padding-top: 0px;
    overflow: overlay;
    border: 3px inset #7a77775c;
    /* transition: .3s; */

}
.items_price_height{
    height: 58vh;
    transition: .5s;

}
span.user-icon {
    font-size: 30px;
    color: #b9b9b9;
}
div.active.list-group-item.list-group-item-action {
    background-color: #f1efef;
    border: none;
    color: inherit;
}
.list-group-item.list-group-item-action
{
   
    padding: 0px 10px;
}
.order-price
{
    font-size: 17px;
}
.optinal_div
{
    margin-top: -8px;
    position: relative;
    background: #e3e3e300;
    z-index: 20;
}
.optinal_div button{
    background: #e3e3e3;
    border: none;
    color: #141518;
    font-size: 18px;
    box-shadow: 0px 0px 6px 0px #3a3a3a4a;
    font-weight: bolder;
}
.optinal_div button:hover{
    background: #df382f !important;
    color: #fff !important;
}
.text_disc
{
    color: #198754;
    font-weight: bold;
}
.visibility_hidden{
    visibility: hidden;
}
