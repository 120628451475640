.setting_btn{
     cursor: pointer;
    color: #eb3d34;
    display: inline-block;
}

.setting_btn svg{
     transition: .6s;
}

.setting_btn:hover svg{
    transform: rotate(70deg);
    transition: .6s;
}

.collapse .SettingSectionCart
{
    /* background: #fcfcfc; */
    border: none;
    box-shadow: 2px 2px 7px 0px #78787887;
}