.open_cash{
    position: fixed;
    background: rgba(0, 0, 0, 0.299);
    z-index: 1000;
    top: 0px;
    bottom: 0px;
    right: 0px;
    left: 0px;
}
 
.open_cash .item{
    background-color: #fff;
    margin-top: 10% !important;
    border-radius: 20px;
}
.open_cash .item h5{
    background-color: rgb(238, 237, 237);
    margin-top: 10% !important;
    color: #eb3d34;
    border-radius: 20px 20px 0 0 ;
}
.calc_icon{
    color: #eb3d34;
    font-size: 20px;
    background: rgb(230, 225, 225);
    padding: 10px 12px;
    border-radius: 50% !important;
}
.open_cash textarea{
    background: rgb(238, 237, 237);
    
}
.input_total_price{
    width: 100px;
    text-align: center;
    border-radius: 21px;
}
.open_cash .calc_div{
    position: absolute;
    top: 0px;
    bottom: 0px;
    right: 0px;
    left: 0px;
    background-color: rgba(0, 0, 0, 0.052);
    border-radius: 20px;

}
.back_light{
    background-color: #fff;
    border-radius: 20px;
    padding: 10px;
}
.div_total{
  background: rgb(238, 237, 237);;
}
/* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
 input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
/* .open_cash input[type=number] {
  -moz-appearance: textfield;
} */
.h_input_calc{
  
    height: 230px;
;
    overflow-x: auto;
}
@media screen and (min-width:768px) {
    .w-resp-opnCash{
        width: 60%;
    }
}
.select__single-value.css-1dimb5e-singleValue {
    height: 100%;
    line-height: 2;
}
.select{
    line-height: 3;
}
.select__menu{
    line-height: 1;
}