.NavComp{
    background-color: #EFEFEF;
    color: #2a2f38;
    padding: 2px;
    font-size: 18px;
    font-weight: bold;
}
.NavComp .nav-link {
    font-size: 18px;
     padding: 10px;
}
.Not_available a{
    cursor: not-allowed !important;
}