.SelectUserToPay {
    background: rgb(0 0 0 / 1%);
}
.height_users_div{
    height: 61vh;
        overflow: auto;
}
.height_users_div table thead
{
    position: sticky;
    top: 0;
    background: #EFEFEF;
}