.div_lift_Filter_session {
    position: unset !important;
    display: flex;
    align-items: center;
    
}  
.div_lift_Filter_session div{
    border-radius:10px ;
    
    }
    .activeIconFilter{
        transform: rotate(139deg);
        transition: .4s;
        color: #eb3d34;
    }
    div#FilterTime_collapse{ border: 1px solid rgb(221 221 221 / 62%);
    padding: 10px;
    border-radius: 20px;
    box-shadow: rgb(221 221 221 / 31%) 0px 0px 4px;
    margin-bottom: 10px;}