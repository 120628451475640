.card .card-body {
    font-size: 16px;
    color: #2a2f38;
    height: unset !important;
    padding: 2px;
    overflow: hidden;
}
.card{
    position: relative;
    height: 100%;
    cursor: pointer;
}
.card img {
    height: 80px;
    object-fit: cover;
}

span.price {
    position: absolute;
    background: #eb3d34;
    color: #FFff;
    padding: 0px 10px;
    border-radius: 20px;
    font-size: 18px;
    top: 9px;
    left: 4px;
}
.detailes{
    position: absolute;
    background: #2a2f38;
    color: #FFff;
    padding: 0px 5px;
    border-radius: 50%;
    width: 20px;
    line-height: 1.1;
    top: 8px;
    right: 1px;
    font-size: 19px;
    height: 20px;
}
.brand_logo {
    position: absolute;
    z-index: 30;
    top: 0;
    left: 0;
}
.brand_logo img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 1px solid #e9ecef;
    padding: 1px;
}
 
 