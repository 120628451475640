.AllSession{
    min-height: 83vh;
}
.AllSession table thead{
    background:#efefef;
}
.AllSession table tbody tr{
    cursor: pointer;
}
.active>.page-link, .page-link.active {
    z-index: 3;
    color: var(--bs-pagination-active-color);
    background-color: #eb3d34;
    border-color: #eb3d34;
}
