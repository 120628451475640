 table.selectuser tbody tr{
    cursor:pointer
}
 table.selectuser tbody tr:hover td{
    background: #eb3d34;
    color: #fff !important;
    border: none;
}
  table.selectuser tbody tr.active td{
    background: #eb3d34;
    color: #fff !important;
    border: none;
}
