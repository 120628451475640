
@media screen and (max-width:748px) {
.calculator-key {
    font-size: 18px !important;
  
}

  
}


.name-text{
    color: rgb(0, 0, 0);
    font-size: 20px;
}
.cards{
    background-color: #f1f1f1;
    margin-top: 10px;
    border-radius: 17px;
    overflow: scroll;
    height: 76vh;
}
.user-item{
    width: 100%;
    background-color: #f3f3f3;
    font-size: 17px;
    border: 1px solid #d0d0d0;
}
.icon-dedails{
    color: #fb3e33;
    font-size: 17px;
    padding-right: 0px;
}

.buttons-icon{
    font-size: 15px;
    height: 40px;
    overflow: hidden;
}
.paym-text {
    color: #2a2f38;
    text-decoration: none;
    
}
a {
  text-decoration: none;
}
.functions
{
    bottom: 23%;
    width: 25%;
    right: 4px;
    background: #f3f3f4;

}

  .functions .calculator-key {
        font-size: 1.6em !important;
        width: 110% !important;
        line-height: 1;
        border-radius: 0px !important
    }

    

    .calculator-key.active
    {
        background-color: #eb3d34 !important;
        color:#fff;
    }
    ::-webkit-scrollbar {
        width: 7px;
      }
      
      /* Track */
      ::-webkit-scrollbar-track {
        background: #f1f1f1; 
      }
       
      /* Handle */
      ::-webkit-scrollbar-thumb {
        background: rgb(168, 160, 160); 
      }
      
      /* Handle on hover */
      ::-webkit-scrollbar-thumb:hover {
        background: #555; 
      }
      .item-payment{
        height: 322px;
    font-size: 26px;
    background: #f1f1f1
      }
      .home-contect{
        width: calc(100% - 63px);
      }
    .css-26l3qy-menu{
      top: auto !important;
  bottom: 100% !important;
    }
.parind_session{
  top: 0px;
  z-index: 30;
  left: 0px;
}
.parind_session svg{
  background: #fff;
  border-radius: 50%;
}