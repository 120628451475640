.Tabels{
    min-height: 85vh ;
}
.react-draggable {
    position: absolute;
}
.shapes_div {
    width: 100px;
    height: 100px;
    cursor: grabbing;
    /* background: rgb(0 0 0 / 8%); */
    background: linear-gradient(90deg, #B58357 20%, #EBD5B7 20%, #EBD5B7 25%, #B58357 25%, #B58357 45%, #EBD5B7 45%, #EBD5B7 50%, #B58357 50%, #B58357 70%, #EBD5B7 70%,#EBD5B7 74%,#B58357 48%);
transform:
    perspective(900px)
    rotateX(60deg)
    scale(1);
    box-shadow: 15px 20px 20px 0px #584f4fa3;
    margin: 3px;
 position: relative;
    box-shadow: 3px 4px 6px 2px #bfbfbfd4;
  }
  .circle_div {
   
    border-radius: 50% !important;
  }
  .triangle_div{
    border-radius: 41px !important;
    width: 150px ;
  }
  .rectangle_div{
    width: 200px;
    height: 100px;
  }
  section.roomSection {

    background: #fbfbfb;
   
}
.conent_Draggable{
  direction: ltr !important;
  position: relative;
  overflow: auto;
  min-height: 56vh;
  border: 2px solid #a0a0a063;
  margin: 30px;
  background: #fff;
  border-radius: 20px;
}
.btns_Tabels button{
  width: 50px  ;
  height: 50px;
}
.btns_Tabels button.rectangle_div{
  width: 100px  !important;
  height: 50px;
}
.btns_Tabels button.triangle_div{
  width: 100px  !important;
 }
section.roomSection   .shapes_div_input input
{
  width: 35px !important;
  height: 35px !important;
  text-align: center;
  border-radius: 50% !important;
}
section.roomSection   .shapes_div_input input:focus{
border-color: #fbfbfb;
}
.remove_btn{
  color: #DF382F;
  position: absolute;
  left: -6px;
  top: -29px;
  transform: scaleY(1.5);
}
section.roomSection   .shapes_div_input
{
  position: absolute;
  bottom: 28%;
  right: 10%;
  left: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.worning_requert{
  border: 2px solid #DF382F !important; 
}
input:focus{
  border: none !important 
  
}
section.roomSection   .shapes_div_input .form-control:focus {
  color: #212529;
  background-color: #fff;
  transform: scale(1.1);
  transition: .5s;
  box-shadow:none !important;
  border-color: inherit;
}