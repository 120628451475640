.offcanvas.offcanvas-end {
  /* top:  var(--bs-offcanvas-width ); */
  bottom: 47px;
  right: 0px;
  width: var(--bs-offcanvas-width);
  border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateX(100%);
}
.bg_nav_select {

  background: #fff;
  border-radius: 29px;
  padding: 0px;
  margin-right: 10px;
  margin-left: 26px;
  flex-direction: column;
  align-content: center;
   box-shadow: 0px 0px 11px 0px #ddd;
  transition: .4s;
  width:300px !important;
}
@media screen and (max-width:420px) {
  .bg_nav_select {
    box-shadow: 0px 0px 14px 0px #ddd;
    width: 92% !important;
    position: absolute;
    height: 70vh;
    right: 4% !important;
    left: 4% !important;
    margin: 0;

    
}
}
.bg-nav{

  background: #fff;
  border-radius: 53px;
  padding: 0px;
  margin-right: 10px;
  margin-left: 10px;
  flex-direction: column;
  align-content: center;
  box-shadow: -2px 2px 17px #ddd;
  margin-top: 11px;
  transition: .4s;
  width: 54px;
  direction: initial;

  overflow: auto;

    
}
.bg-nav .name-text {

  transform: scale3d(1.3, 1.3, 1.3) rotate(89deg);
  margin-top: 27px;
  display: -webkit-box;

  white-space: nowrap;
 
}
 .bg_nav_select select{
 border:none !important;
  width: 95%;
 height: 100% !important ;
  outline: none !important;
  margin-top: 0px;

}
.bg_nav_select select:focus{
  box-shadow: none !important;


  
}
.bg_nav_select select option
{
  border: 1px solid #45454529;
  padding: 5px 6px;
  box-shadow: 2px 6px 6px 0px #eae3e3;
  margin: 11px auto;
  overflow: hidden;
  width: 93%;
  font-size: 16px;
  border-radius: 21px;
}
.bg_nav_select select option:hover,
.bg_nav_select select option:checked{
  background: #eb3d34 !important;
  color: #fff;
  transition: .5s;
}
.icon-home{
  z-index: 40;
  position: relative;
  width: 50px;
  height: 50px;
  background: #eb3d34;
  color: #fff;
  font-size: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}