.PageNotification .item .card:hover{
    background: #dddddd30;
    cursor:unset
}

 
.PageNotification .modal-body {
  
        max-height: 77vh;
    }
 