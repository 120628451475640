.card-body {
    font-size: 14px;
    color: #2a2f38;
    height: 51px;
    padding: 2px;
    overflow: hidden;
}
.card{
    position: relative;
}
span.price {
    position: absolute;
    background: #eb3d34;
    color: #FFff;
    padding: 0px 8px;
    border-radius: 8px;
    font-size: 16px;
    top: 8px;
    left: 1px;
}
 
.key-clear{
  text-align: center;
  width: 33%;
  background: #fff;
  border: 7px solid #f6f6f6;
  color: #2a2f38;
  font-size: 26px;
  height: 60px;
  line-height: 1.8;
  cursor: pointer;

}


html {
    box-sizing: border-box;
  }
  *, *:before, *:after {
    box-sizing: inherit;
  }
  
  body {
    margin: 0;
    font: 100 14px 'Roboto';
  }
  
  button {
    display: block;
    background: none;
    border: none;
    padding: 0;
    font-family: inherit;
    user-select: none;
    cursor: pointer;
    outline: none;
    
    -webkit-tap-highlight-color: rgba(0,0,0,0);
  }
  
  button:active {
    box-shadow: inset 0px 0px 80px 0px rgba(0,0,0,0.25);
  }
  
  #wrapper {
    height: 100vh;
    
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  #app {
    width: 320px;
    height: 520px;
    position: relative;
  }
  
  .calculator {
    width: 100%;
    height: 100%;
 
    
    display: flex;
    flex-direction: column;
  }
  
  #wrapper .calculator {
    box-shadow: 0px 0px 20px 0px #aaa;
  }
  
  .calculator-display {
    color: white;
    background: #1c191c;
    line-height: 130px;
    font-size: 6em;
    
    flex: 1;
  }
  
  .auto-scaling-text {
    display: inline-block;
  }
  
  .calculator-display .auto-scaling-text {
    padding: 0 30px;
    position: absolute;
    right: 0;
    transform-origin: right;
  }
  
  .calculator-keypad {
    display: flex;
    justify-content: center;
    align-items: flex-end;    
  }
  
  .calculator .input-keys {
    width: 100%;
  }
  

  
  .calculator .digit-keys {
    background: #f3f3f4;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    /* border-radius: 23px; */
  }
 button.calculator-key.key-1{
  border-top-left-radius: 23px;
 }
 button.calculator-key.key-sign {
  border-bottom-left-radius: 23px;
}
button.key-clear{
  border-bottom-right-radius: 23px;

}
  .calculator-key {
    height: 50px;
    text-align: center;
    line-height: 38px;
    width: 33%;
    background: #fff;
    border: 6px solid #f6f6f6;
    color: #2a2f38;
  }
  .calculator .function-keys .calculator-key {
    font-size: 2em;
  }
  .calculator .function-keys .key-multiply {
    line-height: 50px;
  }
  .calculator .digit-keys .calculator-key {
    font-size: 1.8em;
    cursor: pointer;
  }
  .calculator .digit-keys .key-0 {
    text-align: left;
    text-align: center;
  }
  .calculator .digit-keys .key-dot {
    padding-top: 1em;
    font-size: 0.75em;
    line-height: 0;
  }
  .calculator .operator-keys .calculator-key {
    color: white;
    border-right: 0;
    font-size: 3em;
  }
  
 
  .calculator .operator-keys {
    background:  linear-gradient(to bottom, rgba(252,156,23,1) 0%, rgba(247,126,27,1) 100%);
  }
.calculator-key.active{
  color:#fff;
  background-color: #2a2f38;
}
button.key-clear {
  font-size: 30px;
  line-height: 1;
}