.logo  img{
    width: 75%;

    }

.nav-link{
    font-size: 20px;
    color: #2a2f38 !important;
}
.button-search{
    margin-left: -48px;
    background: #eb3d34;
    border-radius: 39.09px !important;
    color: #fff;
    font-size: 27px;
    font-weight: bold;
    width: 39.87px;
    text-align: center;
    border: none;
    line-height: 0px;
    height: 32.18px;
}

.input-search{
    height: 32.18px;
    overflow: hidden;
    border-radius: 25px;
    font-size: 22px;
}
@media screen and (min-width:994px) {
    .input-search{
        width: 100% !important;
    }
}
.logon-admin{
    font-size: 23px;
    color:#2a2f38
}

.logo img {
    width: 72%;

    }
    .logo{
        width: 200px;
        }
    .nav-item{
       
        margin-right: 11px;
        /* min-width: 138px; */
        margin-top: 12px;

    }
@media  screen and  (max-width:768px) {
    /* .logo {
       width: 73%;
    
        } */
}
@media  screen and  (min-width:1444px) {
    .nav-item{
        margin-right: 20px;
    }
   
    .item-search{
        width: 100%;
    }
}
.btn_full_screen{
    background: #eb3d34;
    color: #fff;
    font-weight: bold;
}
.btn_full_screen:hover{
border:1px solid  #eb3d34 !important;
color: #2a2f38 !important;

}
.btn_full_screen:hover span.open_icon svg
{
    transform: scale(1.3);
    transition: .3s;
}
.btn_full_screen span.close_icon svg{
    transform: scale(1.3);

}
.btn_full_screen:hover span.close_icon svg
{
    transform: scale(1);
    transition: .3s;
}
.back-massge 
 {
    background: #F4F4F4;
    color: #2B2F38;
    font-size: 20px;
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.Number_notification {
    background: #eb3d34 !important;
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 13px;
}
.button-search:hover{
    background-color: #eb3d34 !important ;
}
.button-search:hover svg{
    transform: scale(1.2);
    transition: .5s;
}
    
.DropdownUser .dropdown-menu[data-bs-popper]{
    left: -82px;
    top: 141%;
    border: none;
    box-shadow: 0px 2px 4px 0px #ced4da;
    cursor: pointer;
}
.DropdownUser .dropdown-menu[data-bs-popper] li:active{
    background: unset ;
    color: unset;
}
.icon_red{
    color: #eb3d34;
}