.refund{
    min-height: 82vh;
}
.content_orders table{
background: #fff;
border: 1px solid #e2e2e2;
 }
.content_orders table thead td{
color: #999;
}
.content_orders 
{
    background: #F4F4F4;
}
.content_orders table tbody tr{
    cursor:pointer
}
  table tbody tr:hover td{
    background: #eb3d34;
    color: #fff !important;
    border: none;
}
 table tbody tr.active td{
    background: #eb3d34;
    color: #fff !important;
    border: none;
}
.content_orders .div_tabel{
   
    max-height: 67vh;
    background: #fff;
    font-size: 16px;

}