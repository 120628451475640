.dark_btn{
    background: #2a2f38;
    font-size: 22px;
    color: #fff;
}
.dark_btn:hover{
    background: #22252b !important;
    font-size: 22px;
    color: #fff !important;
}
.red_btn{
    background: #eb3d34;
    font-size: 22px;
    color: #fff;
}
.red_btn:hover{
    background: #df382f !important;
    font-size: 22px;
    color: #fff !important;
}
a {
    color: inherit;
}
.cursor_pointer{
    cursor: pointer;
}
.header_print_block,.footer_print_block{
    display: none;

}
.header_print,.footer_print{
    display: none;
   }

@media print {
    .header_print,.footer_print{
     display: table-row;
    };
   
  
}
@media print {
    
    .header_print_block,.footer_print_block{
        display:block;
       };
  
}
.headerPrint{
    border-bottom: 1px solid #ddd;
    margin-bottom: 10px;
}